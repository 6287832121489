html {
  font-family: "Poppins", sans-serif;
  height: 100%;
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}
